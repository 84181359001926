import axios from "axios";
import { apiUrl } from "../api/enviroment";
import { ApiRoutes } from "../api/api_routes";
import TokenManager from "../token_manager";

const doctoresController = {
  async getDoctores() {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.getDoctores}`;

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        return { ok: false };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },
  async createDoctor(doctor) {
    const token = TokenManager.getToken();
    const url = `${apiUrl}/${ApiRoutes.createDoctor}`;

    try {
      const response = await axios.post(url, doctor, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        return { ok: true };
      } else {
        return { ok: false, error: response.data.message || "Error al crear doctor" };
      }
    } catch (error) {
      return { ok: false, error: error.message };
    }
  },
 
};

export default doctoresController;
