<template>
  <div
    class="modal fade"
    id="prefacturaModal"
    tabindex="-1"
    aria-labelledby="prefacturaModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="prefacturaModalLabel">
            Nueva Pre-Factura
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeTab === 'tab1' }"
                href="javascript:void(0)"
                @click="setActiveTab('tab1')"
                >Tipo de Facturación</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{
                  active: activeTab === 'tab2',
                  disabled: tipoCliente == '',
                }"
                href="javascript:void(0)"
                @click="setActiveTab('tab2')"
                >Seleccionar Pacientes</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{
                  active: activeTab === 'tab3',
                  disabled: !isTab2Complete,
                }"
                href="javascript:void(0)"
                @click="setActiveTab('tab3')"
                >Detalles de Pre-Factura</a
              >
            </li>
            <li v-if="tipoCliente === 'No Asegurado'" class="nav-item">
              <a
                class="nav-link"
                :class="{
                  active: activeTab === 'tab4',
                  disabled: !isTab3Complete,
                }"
                href="javascript:void(0)"
                @click="setActiveTab('tab4')"
                >Resumen Prefactura</a
              >
            </li>
          </ul>
          <div class="tab-content mt-3">
            <div
              class="tab-pane fade text-center"
              id="tab1"
              :class="{ 'show active': activeTab == 'tab1' }"
            >
              <div class="mb-3">
                <p
                  style="font-size: 23px; font-weight: bold; margin-bottom: 0px"
                >
                  Elija la forma en que desea facturar
                </p>
                <p style="font-size: 14px">
                  Permítanos encontrar la mejor forma de ayudarle. Usted
                  continúa como:
                </p>
                <div>
                  <button
                    type="button"
                    class="btn btn-outline-primary me-2"
                    :class="{ active: tipoCliente === 'No Asegurado' }"
                    @click="setTipoCliente('No Asegurado')"
                    style="height: 205px; width: 205px"
                    @mouseover="changeHoverColor"
                  >
                    No Asegurado
                  </button>
                  <button
                    type="button"
                    style="height: 205px; width: 205px"
                    class="btn btn-outline-primary"
                    :class="{ active: tipoCliente === 'Asegurado' }"
                    @click="setTipoCliente('Asegurado')"
                    @mouseover="changeHoverColor"
                  >
                    Asegurado
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content mt-3">
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'tab2' }"
              id="tab2"
            >
              <form>
                <div class="mb-3">
                  <label for="pacientes" class="form-label"
                    >Seleccione los pacientes que desea incluir en su
                    pre-factura:</label
                  >
                  <div class="row">
                    <div
                      class="col-6"
                      v-for="(paciente, index) in pacientes"
                      :key="index"
                      style="cursor: pointer"
                      @click="selectPaciente(paciente)"
                    >
                      <div
                        class="row m-1 p-2"
                        style="border: 1px solid #0000001c; border-radius: 10px"
                        :class="{
                          'selected-patient':
                            selectedPacientes &&
                            selectedPacientes.some(
                              (x) => x.PacienteId == paciente.PacienteId
                            ),
                        }"
                      >
                        <div class="col-3 pt-1 text-center">
                          <div class="icon icon-shape text-center">
                            <i
                              style="font-size: 47px; position: inherit"
                              class="ni ni-single-02 text-dark opacity-10"
                            ></i>
                          </div>
                        </div>
                        <div class="col-9">
                          <p
                            style="
                              font-size: 14px;
                              margin: 0;
                              font-weight: bold;
                            "
                          >
                            {{ paciente.NombreCompleto }}
                          </p>
                          <p
                            style="
                              font-size: 12px;
                              margin: 0;
                              font-weight: bold;
                            "
                          >
                            {{ paciente.Email }}
                          </p>
                          <p style="font-size: 12px; margin: 0; color: #008335">
                            Titular
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-6"
                      v-for="(paciente, index) in dependientes"
                      :key="index"
                      style="cursor: pointer"
                      @click="selectPaciente(paciente)"
                    >
                      <div
                        class="row m-1 p-2"
                        style="border: 1px solid #0000001c; border-radius: 10px"
                        :class="{
                          'selected-patient':
                            selectedPacientes &&
                            selectedPacientes.some(
                              (x) => x.DependienteId == paciente.DependienteId
                            ),
                        }"
                      >
                        <div class="col-3 pt-1 text-center">
                          <div class="icon icon-shape text-center">
                            <i
                              style="font-size: 47px; position: inherit"
                              class="ni ni-single-02 text-dark opacity-10"
                            ></i>
                          </div>
                        </div>
                        <div class="col-9">
                          <p
                            style="
                              font-size: 14px;
                              margin: 0;
                              font-weight: bold;
                            "
                          >
                            {{ paciente.NombreCompleto }}
                          </p>
                          <p
                            style="
                              font-size: 12px;
                              margin: 0;
                              font-weight: bold;
                            "
                          >
                            {{ paciente.Email }}
                          </p>
                          <p style="font-size: 12px; margin: 0; color: #008335">
                            Dependiente
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'tab3' }"
              id="tab3"
            >
              <div class="mb-3">
                <label for="pacientesSeleccionados" class="form-label"
                  >Pacientes Seleccionados</label
                >
                <div
                  v-for="(paciente, index) in selectedPacientes"
                  :key="index"
                  class="card p-2 mb-2"
                >
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <span>{{ paciente.NombreCompleto }}</span>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="removePaciente(index)"
                      style="position: absolute; right: 0; height: 100%; top: 0"
                    >
                      <i class="fas fa-trash" style="font-size: 14px"></i>
                    </button>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <div class="file-upload me-3">
                      <input
                        type="file"
                        :id="'cedula' + index"
                        ref="cedula"
                        style="display: none"
                        @change="uploadFile('cedula', index)"
                      />
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        style="
                          height: 175px;
                          width: 205px;
                          border-style: dashed;
                        "
                        :class="{
                          'file-uploaded': paciente.cedulaFile?.name,
                        }"
                        @click="triggerUpload('cedula', index)"
                      >
                        {{
                          paciente.cedulaFile?.name ??
                          "Haga click aquí para cargar Cédula"
                        }}
                      </button>
                    </div>

                    <div
                      v-if="tipoCliente === 'Asegurado'"
                      class="file-upload me-3"
                    >
                      <input
                        type="file"
                        :id="'seguro' + index"
                        ref="seguro"
                        style="display: none"
                        @change="uploadFile('seguro', index)"
                      />
                      <button
                        type="button"
                        style="
                          height: 175px;
                          width: 205px;
                          border-style: dashed;
                        "
                        :class="{
                          'file-uploaded': paciente.seguroFile?.name,
                        }"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerUpload('seguro', index)"
                      >
                        {{
                          paciente.seguroFile?.name ??
                          "Haga click aquí para cargar Seguro"
                        }}
                      </button>
                    </div>

                    <div class="file-upload">
                      <input
                        type="file"
                        :id="'indicacion' + index"
                        ref="indicacion"
                        multiple
                        style="display: none"
                        @change="uploadFile('indicacion', index)"
                      />
                      <button
                        type="button"
                        style="
                          height: 175px;
                          width: 205px;
                          border-style: dashed;
                        "
                        :class="{
                          'file-uploaded': paciente.indicacionFile?.length > 0,
                        }"
                        class="btn btn-sm btn-outline-secondary"
                        @click="triggerUpload('indicacion', index)"
                      >
                        {{
                          paciente.indicacionFile?.length > 0
                            ? Array.from(paciente.indicacionFile)
                                .map((file) => file.name)
                                .join(", ")
                            : "Haga click aquí para cargar Indicación"
                        }}
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="tipoCliente === 'No Asegurado'"
                    id="PruebasARealizar"
                  >
                    <label for="pacientesSeleccionados" class="form-label"
                      >Pruebas a Realizar</label
                    >
                    <multiselect
                      v-model="paciente.pruebasSelected"
                      :options="pruebas"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="true"
                      :hide-selected="false"
                      placeholder="Seleccione las Pruebas a Realizar"
                      label="NombrePrecio"
                      track-by="NombrePrecio"
                      class="w-80"
                    >
                      <template #selection="{ values, search, isOpen }">
                        <span
                          class="multiselect__single"
                          v-if="values.length"
                          v-show="!isOpen"
                          >{{
                            values
                              ? Array.from(values)
                                  .map((x) => x.NombrePrecio)
                                  .join(", ")
                              : ""
                          }}</span
                        >
                        <span v-if="false"> {{ search }} </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'tab4' }"
              id="tab4"
            >
              <div
                v-for="paciente in selectedPacientes"
                :key="paciente"
                class="mb-4"
              >
                <div class="row mb-4">
                  <div class="col-md-12 mb-2">
                    <strong>Tipo de Paciente:</strong> {{ tipoCliente }}
                  </div>
                  <div class="col-md-12">
                    <strong>Paciente:</strong>
                    {{ paciente.NombreCompleto }}
                  </div>
                </div>
                <table class="w-100 table-bordered table-hover trable-striped">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Concepto</th>
                      <th>¿En Ayunas?</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in paciente.pruebasSelected"
                      :key="item.pruebaId"
                    >
                      <td>{{ item.codigoAS400 }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.ayuna ? "Sí" : "No" }}</td>
                      <td>
                        {{
                          item.precio.toLocaleString("es-DO", {
                            style: "currency",
                            currency: "DOP",
                          })
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td class="text-end">
                        {{
                          paciente.pruebasSelected
                            .reduce((sum, request) => sum + request.precio, 0)
                            .toLocaleString("es-DO", {
                              style: "currency",
                              currency: "DOP",
                            })
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="w-100 table-bordered table-hover trable-striped">
                <tbody>
                  <tr>
                    <td>Total a pagar</td>
                    <td class="text-end">
                      {{
                        selectedPacientes
                          .reduce((sum, paciente) => {
                            const subtotal = paciente.pruebasSelected.reduce(
                              (pruebaSum, prueba) => pruebaSum + prueba.precio,
                              0
                            );
                            return sum + subtotal;
                          }, 0)
                          .toLocaleString("es-DO", {
                            style: "currency",
                            currency: "DOP",
                          })
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="goToPreviousTab"
            v-if="
              activeTab === 'tab2' ||
              activeTab === 'tab3' ||
              (activeTab === 'tab4' && tipoCliente === 'No Asegurado')
            "
          >
            Anterior
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="goToNextTab"
            v-if="
              activeTab === 'tab1' ||
              activeTab === 'tab2' ||
              (activeTab === 'tab3' && tipoCliente === 'No Asegurado')
            "
          >
            Siguiente
          </button>
          <button
            type="button"
            class="btn btn-outline-success"
            @click="saveChanges"
            v-if="
              (activeTab === 'tab3' && tipoCliente === 'Asegurado') ||
              activeTab === 'tab4'
            "
          >
            Guardar Cambios
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="successModal"
    tabindex="-1"
    aria-labelledby="successModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="successModalLabel">Guardado con éxito</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">Los cambios se guardaron correctamente.</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import DependientesController from "../../../utils/http/Dependientes";
import { apiUrl } from "../../../utils/api/enviroment";
import { ApiRoutes } from "../../../utils/api/api_routes";
import TokenManager from "../../../utils/token_manager";
import bootstrap from "bootstrap/dist/js/bootstrap";
import Swal from "sweetalert2";
import prefacturaController from "../../../utils/http/PreFactura";
import Multiselect from "vue-multiselect";

const activeTab = ref("tab1");
const isTab1Complete = ref(false);
const isTab2Complete = ref(false);
const isTab3Complete = ref(false);
const selectedPacientes = ref([]);
const tipoCliente = ref("");
const pacientes = ref([]);
const pruebas = ref([]);
const dependientes = ref([]);

const fetchData = async () => {
  try {
    const response = await DependientesController.getDependientes();
    if (response && response.Paciente && response.Dependientes) {
      response.Paciente.pruebasSelected = [];
      pacientes.value = [response.Paciente];
      dependientes.value = response.Dependientes.map((x) => ({
        ...x,
        pruebasSelected: [],
      }));
    } else {
      console.error("Estructura de respuesta inesperada:", response);
    }
    getPruebas();
  } catch (error) {
    console.error("Error fetching pacientes:", error);
  }
};

const getPruebas = async () => {
  const data = await prefacturaController.getPruebas();
  if (data) {
    pruebas.value = data.map((request) => ({
      ...request,
      NombrePrecio: `${request.nombre} (${request.monedaId} ${request.precio.toLocaleString("es-DO", { style: "currency", currency: "DOP" })})`,
    }));
  }
};

onMounted(fetchData);

const setActiveTab = (tab) => {
  activeTab.value = tab;
};

const goToNextTab = () => {
  var num = Number(activeTab.value.replace(/^\D+/g, ""));
  switch (num) {
    case 1:
      goToTab2();
      break;
    case 2:
      goToTab3();
      break;
    case 3:
      goToTab4();
      break;
    case 4:
      break;
    default:
      break;
  }
};

const goToTab2 = () => {
  selectedPacientes.value = [...selectedPacientes.value];
  isTab1Complete.value = activeTab.value == "tab2";
  var num = Number(activeTab.value.replace(/^\D+/g, ""));
  if (tipoCliente.value == "") {
    alert("Por favor, seleccione el tipo de facturación para continuar.");
    return false;
  }
  setActiveTab("tab" + (num + 1).toString());
};
const goToTab3 = () => {
  var num = Number(activeTab.value.replace(/^\D+/g, ""));
  isTab2Complete.value = activeTab.value == "tab3";
  if (selectedPacientes.value.length == 0) {
    alert("Por favor, seleccione al menos un paciente antes de continuar.");
    return false;
  }
  setActiveTab("tab" + (num + 1).toString());
};
const goToTab4 = () => {
  var num = Number(activeTab.value.replace(/^\D+/g, ""));
  isTab3Complete.value = activeTab.value == "tab4";
  if (selectedPacientes.value.some((x) => x.pruebasSelected.length === 0)) {
    alert(
      "Por cada paciente a prefacturar debe seleccionar al menos una prueba."
    );
    return false;
  }
  setActiveTab("tab" + (num + 1).toString());
};

const goToPreviousTab = () => {
  var num = Number(activeTab.value.replace(/^\D+/g, ""));
  setActiveTab("tab" + (num - 1).toString());
};

const saveChanges = async () => {
  if (tipoCliente.value == "Asegurado") {
    await saveChangesAsegurado();
  } else {
    await saveChangesNoAsegurado();
  }
};

const saveChangesAsegurado = async () => {
  const url = `${apiUrl}/${ApiRoutes.AddPrefactura}`;
  const token = TokenManager.getToken();
  try {
    if (selectedPacientes.value.length == 0) {
      Swal.fire({
        title: "Debe seleccionar al menos un paciente",
        icon: "error",
      });
      return;
    }
    if (tipoCliente.value == "Asegurado") {
      if (
        selectedPacientes.value.some(
          (x) => !x.cedulaFile || !x.indicacionFile || !x.seguroFile
        )
      ) {
        Swal.fire({
          title: "Todos los documentos son requeridos",
          icon: "error",
        });
        return;
      }
    }
    for (const paciente of selectedPacientes.value) {
      const preFacturaData = {
        PacienteId: paciente.PacienteId,
        Identificacion: paciente.Identificacion,
        NombrePaciente: paciente.NombreCompleto,
        NumeroAfiliado: paciente.Seguro,
        Comentario: paciente.Comentario || "",
        Direccion: paciente.Direccion,
        Telefono: paciente.Telefono,
        Movil: paciente.Movil,
        Correo: paciente.Email,
        MunicipioId: paciente.MunicipioID,
        FechaNacimiento: paciente.FechaNacimiento,
        RncCedula: paciente.Identificacion,
        TipoIdentificacion: paciente.TipoDocumentoID || 1,
        Sexo: paciente.Sexo,
      };

      const response = await axios.post(url, preFacturaData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Convertimos el archivo a Base64
      let base64String = (await toBase64(paciente.cedulaFile))
        .replace("data:", "")
        .replace(/^.+,/, "");

      let nombreArchivo = paciente.cedulaFile.name;
      let extension = nombreArchivo.split(".").pop();
      let formatoImagen = paciente.cedulaFile.type;

      let cedula = {
        ImagenBase64: base64String,
        TipoImagenID: 1,
        FormatoImagen: formatoImagen,
        NombreArchivo: nombreArchivo,
        Extension: extension,
      };

      base64String = (await toBase64(paciente.seguroFile))
        .replace("data:", "")
        .replace(/^.+,/, "");

      nombreArchivo = paciente.seguroFile.name;
      extension = nombreArchivo.split(".").pop();
      formatoImagen = paciente.seguroFile.type;

      let seguro = {
        ImagenBase64: base64String,
        TipoImagenID: 3,
        FormatoImagen: formatoImagen,
        NombreArchivo: nombreArchivo,
        Extension: extension,
      };

      let indicaciones = [];

      Array.from(paciente.indicacionFile).forEach(async (element) => {
        base64String = (await toBase64(element))
          .replace("data:", "")
          .replace(/^.+,/, "");

        nombreArchivo = element.name;
        extension = nombreArchivo.split(".").pop();
        formatoImagen = element.type;

        indicaciones.push({
          ImagenBase64: base64String,
          TipoImagenID: 5,
          FormatoImagen: formatoImagen,
          NombreArchivo: nombreArchivo,
          Extension: extension,
        });
      });

      var payload = [
        {
          ImagenID: 1,
          Imagen1: cedula.ImagenBase64,
          TipoImagenID: 1,
          PacienteID: paciente.PacienteId,
          numeroLaboratorio: response.data.NumeroLaboratorio,
          FormatoImagen: "BASE64",
          Fecha: new Date(),
          NumeroPreFactura: response.data.NumeroPreFactura,
          NombreArchivo: cedula.NombreArchivo,
          Extension: ".jpg",
        },
        {
          ImagenID: 3,
          Imagen1: seguro.ImagenBase64,
          TipoImagenID: 3,
          PacienteID: paciente.PacienteId,
          numeroLaboratorio: response.data.NumeroLaboratorio,
          FormatoImagen: "BASE64",
          Fecha: new Date(),
          NumeroPreFactura: response.data.NumeroPreFactura,
          NombreArchivo: seguro.NombreArchivo,
          Extension: ".jpg",
        },
      ];
      indicaciones.forEach((element) => {
        payload.push({
          ImagenID: 5,
          Imagen1: element.ImagenBase64,
          TipoImagenID: 5,
          PacienteID: paciente.PacienteId,
          numeroLaboratorio: response.data.NumeroLaboratorio,
          FormatoImagen: "BASE64",
          Fecha: new Date(),
          NumeroPreFactura: response.data.NumeroPreFactura,
          NombreArchivo: element.NombreArchivo,
          Extension: ".jpg",
        });
      });

      await prefacturaController.submitFacturasDocuments(payload);
      if (response.status !== 200) {
        throw new Error("Error al guardar cambios.");
      }

      const successModal = document.getElementById("successModal");
      const modal = new bootstrap.Modal(successModal);
      modal.show();

      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  } catch (error) {
    console.error("Error al guardar cambios:", error);
    alert("Hubo un error al guardar los cambios.");
  }
};
const saveChangesNoAsegurado = async () => {
  try {
    const url = `${apiUrl}/${ApiRoutes.setPrefacturaPruebas}`;
    const token = TokenManager.getToken();
    for (const paciente of selectedPacientes.value) {
      console.log(paciente);

      const preFacturaData = {
        pruebas: paciente.pruebasSelected.map((x) => ({
          acuerdoId: x.acuerdoId,
          pruebaId: x.pruebaId,
          nombre: x.nombre,
          codigoAS400: x.codigoAS400,
        })),
        prefactura: {
          PacienteID: paciente.PacienteId,
          SubTotal: Number(
            paciente.pruebasSelected.reduce(
              (sum, request) => sum + request.precio,
              0
            )
          ),
          Bruto: Number(
            paciente.pruebasSelected.reduce(
              (sum, request) => sum + request.precio,
              0
            )
          ),
          Descuento: 0.0,
          Neto: Number(
            paciente.pruebasSelected.reduce(
              (sum, request) => sum + request.precio,
              0
            )
          ),
        },
      };

      const response = await axios.post(url, [preFacturaData], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      var payload = [];
      if (paciente.cedulaFile) {
        // Convertimos el archivo a Base64
        let base64String = (await toBase64(paciente.cedulaFile))
          .replace("data:", "")
          .replace(/^.+,/, "");

        let nombreArchivo = paciente.cedulaFile.name;
        let extension = nombreArchivo.split(".").pop();
        let formatoImagen = paciente.cedulaFile.type;

        let cedula = {
          ImagenBase64: base64String,
          TipoImagenID: 1,
          FormatoImagen: formatoImagen,
          NombreArchivo: nombreArchivo,
          Extension: extension,
        };
        payload.push({
          ImagenID: 1,
          Imagen1: cedula.ImagenBase64,
          TipoImagenID: 1,
          PacienteID: paciente.PacienteId,
          numeroLaboratorio: response.data.NumeroLaboratorio,
          FormatoImagen: "BASE64",
          Fecha: new Date(),
          NumeroPreFactura: response.data.CodigoFactura,
          NombreArchivo: cedula.NombreArchivo,
          Extension: ".jpg",
        });
      }

      if (paciente.seguroFile) {
        let base64String = (await toBase64(paciente.seguroFile))
          .replace("data:", "")
          .replace(/^.+,/, "");

        let nombreArchivo = paciente.seguroFile.name;
        let extension = nombreArchivo.split(".").pop();
        let formatoImagen = paciente.seguroFile.type;

        let seguro = {
          ImagenBase64: base64String,
          TipoImagenID: 3,
          FormatoImagen: formatoImagen,
          NombreArchivo: nombreArchivo,
          Extension: extension,
        };

        payload.push({
          ImagenID: 3,
          Imagen1: seguro.ImagenBase64,
          TipoImagenID: 3,
          PacienteID: paciente.PacienteId,
          numeroLaboratorio: response.data.NumeroLaboratorio,
          FormatoImagen: "BASE64",
          Fecha: new Date(),
          NumeroPreFactura: response.data.CodigoFactura,
          NombreArchivo: seguro.NombreArchivo,
          Extension: ".jpg",
        });
      }

      let indicaciones = [];
      if (paciente.indicacionFile) {
        Array.from(paciente.indicacionFile).forEach(async (element) => {
          let base64String = (await toBase64(element))
            .replace("data:", "")
            .replace(/^.+,/, "");

          let nombreArchivo = element.name;
          let extension = nombreArchivo.split(".").pop();
          let formatoImagen = element.type;

          indicaciones.push({
            ImagenBase64: base64String,
            TipoImagenID: 5,
            FormatoImagen: formatoImagen,
            NombreArchivo: nombreArchivo,
            Extension: extension,
          });
        });

        indicaciones.forEach((element) => {
          payload.push({
            ImagenID: 5,
            Imagen1: element.ImagenBase64,
            TipoImagenID: 5,
            PacienteID: paciente.PacienteId,
            numeroLaboratorio: response.data.NumeroLaboratorio,
            FormatoImagen: "BASE64",
            Fecha: new Date(),
            NumeroPreFactura: response.data.CodigoFactura,
            NombreArchivo: element.NombreArchivo,
            Extension: ".jpg",
          });
        });
      }
      if (payload.length > 0) {
        await prefacturaController.submitFacturasDocuments(payload);
        if (response.status !== 200) {
          throw new Error("Error al guardar cambios.");
        }
      }

      const successModal = document.getElementById("successModal");
      const modal = new bootstrap.Modal(successModal);
      modal.show();

      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  } catch (error) {
    console.error("Error al guardar cambios:", error);
    alert("Hubo un error al guardar los cambios.");
  }
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const removePaciente = (index) => {
  selectedPacientes.value.splice(index, 1);
};

const selectPaciente = (paciente) => {
  if (isSelectedPaciente(paciente)) {
    selectedPacientes.value = selectedPacientes.value.filter(
      (x) =>
        (x.PacienteId && x.PacienteId !== paciente.PacienteId) ||
        (x.DependienteId && x.DependienteId !== paciente.DependienteId)
    );
  } else {
    console.log(paciente);
    selectedPacientes.value.push(paciente);
  }
};

const isSelectedPaciente = (paciente) => {
  return selectedPacientes.value.some(
    (x) =>
      (x.PacienteId && x.PacienteId == paciente.PacienteId) ||
      (x.DependienteId && x.DependienteId == paciente.DependienteId)
  );
};

const triggerUpload = (type, index) => {
  if (type === "cedula") {
    document.getElementById(`cedula${index}`).click();
  } else if (type === "seguro") {
    document.getElementById(`seguro${index}`).click();
  } else if (type === "indicacion") {
    document.getElementById(`indicacion${index}`).click();
  }
};

const uploadFile = (type, index) => {
  const fileInput = document.getElementById(`${type}${index}`);
  const file = fileInput.files[0];

  if (type === "cedula") {
    selectedPacientes.value[index].cedulaFile = file;
  } else if (type === "seguro") {
    selectedPacientes.value[index].seguroFile = file;
  } else if (type === "indicacion") {
    selectedPacientes.value[index].indicacionFile = fileInput.files;
  }
};

const setTipoCliente = (tipo) => {
  tipoCliente.value = tipo;
};

const changeHoverColor = (event) => {
  event.target.classList.remove("btn-outline-primary");
  event.target.classList.add("btn-outline-success");
};
</script>

<style scoped>
.btn-outline-primary.active,
.btn-outline-primary:hover {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}

.file-upload {
  position: relative;
}

.file-name {
  display: inline-block;
  margin-left: 10px;
}

.file-uploaded {
  border-color: #008335;
  border-style: double;
  color: green;
}
p {
  line-height: 1.5;
}
.selected-patient {
  border-color: #008335 !important;
  border-style: dashed;
}
</style>
