<script setup>
import UserCard from "../components/UserCard.vue";
import DoctoresTable from "../components/doctores/DoctoresTable.vue";
import DoctoresModal from "../components/doctores/DoctoresModal.vue"; 
import bootstrap from "bootstrap/dist/js/bootstrap";

const addNewDoctor = () => {
  const modal = new bootstrap.Modal(document.getElementById('DoctoresModal'));
  modal.show();
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 mb-lg" style="margin-top: 10%;">
        <user-card></user-card>
        <div class="d-flex justify-content-end mb-3">
          <button class="btn btn-outline-success btn-sm" @click="addNewDoctor">
            <i class="fas fa-plus me-2"></i>Agregar Doctor
          </button>
        </div>
        <DoctoresTable></DoctoresTable>
      </div>
    </div>
  </div>

  <DoctoresModal />
</template>

<style scoped>
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  background-color: #28a745;
  color: white;
}
</style>
