<template>
    <div class="card">
      <div class="card-header pb-0 d-flex justify-content-between align-items-center">
        <h6>Gestión de Doctores</h6>
        <select v-model="itemsPerPage" class="form-select form-select-sm">
          <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
            {{ option }} registros por página
          </option>
        </select>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <tbody>
              <tr v-for="doctores in paginatedDoctores" :key="doctores.DoctorID">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        src="../../../assets/img/lab-ref-logo.svg"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">Nombre {{ doctores.Nombre }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        <strong>Especialidad:</strong> {{ doctores.Especialidad }}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div class="me-auto"></div>
          <div class="pagination-buttons">
            <button class="btn btn-custom me-2" @click="prevPage" :disabled="currentPage === 1">
            <i class="fas fa-chevron-left"></i>
          </button>
          <button class="btn btn-custom" @click="nextPage" :disabled="currentPage >= totalPages">
            <i class="fas fa-chevron-right"></i>
          </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import doctoresController from '../../../utils/http/Doctores';
  
  export default {
    data() {
      return {
        doctores: [],
        currentPage: 1,
        itemsPerPage: 5, 
        itemsPerPageOptions: [ 5,10, 15, 20, 50]
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.doctores.length / this.itemsPerPage);
      },
      paginatedDoctores() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.doctores.slice(start, end);
      }
    },
    async created() {
      await this.fetchDoctores();
    },
    methods: {

      async fetchDoctores() {
        const result = await doctoresController.getDoctores();
        if (result.ok !== false) {
          this.doctores = result;
        } else {
          console.error("Error al obtener Doctores", result.error || "No se encontraron Doctores");
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-select {
    width: auto;
    padding-right: 2rem; 
    margin-left: 0.5rem; 
  }
  
  .pagination-buttons {
    padding-right: 20px; 
  }
  
  .btn {
    min-width: 100px;
  }
  .btn-custom {
  background-color: #28a745; 
  color: white;
  border: none;
}

.btn-custom:hover {
  background-color: #218838; 
}

.btn-custom:disabled {
  background-color: #d6d6d6; 
  color: #a0a0a0;
}
  </style>
  