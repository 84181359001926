<template>
  <div class="modal fade" id="DoctoresModal" tabindex="-1" aria-labelledby="DoctoresModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="DoctoresModalLabel">Crear Doctor</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createDoctor">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="nombre" class="form-label">Nombre</label>
                <input v-model="doctor.Nombre" type="text" class="form-control" id="nombre" required />
              </div>
              <div class="col-md-6 mb-3">
                <label for="apellidos" class="form-label">Apellidos</label>
                <input v-model="doctor.Apellidos" type="text" class="form-control" id="apellidos" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="especialidad" class="form-label">Especialidad</label>
                <input v-model="doctor.Especialidad" type="text" class="form-control" id="especialidad" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="tipoIdentificacion" class="form-label">Tipo de Identificación</label>
                <select v-model="doctor.TipoIdentificacion" class="form-select" id="tipoIdentificacion">
                  <option value="1">Cédula</option>
                  <option value="2">Pasaporte</option>
                  <option value="3">Otro</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label for="identificacion" class="form-label">Identificación</label>
                <input v-model="doctor.Identificacion" type="text" class="form-control" id="identificacion" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="exequatur" class="form-label">Exequatur</label>
                <input v-model="doctor.Exequartur" type="text" class="form-control" id="exequatur" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="telefono" class="form-label">Teléfono</label>
                <input v-model="doctor.Telefono" type="text" class="form-control" id="telefono" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="telefono2" class="form-label">Teléfono 2</label>
                <input v-model="doctor.Telefono2" type="text" class="form-control" id="telefono2" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="movil" class="form-label">Móvil</label>
                <input v-model="doctor.Movil" type="text" class="form-control" id="movil" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="correo" class="form-label">Correo</label>
                <input v-model="doctor.Correo" type="email" class="form-control" id="correo" />
              </div>
            </div>
          
          </form>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-outline-success">Guardar Doctor</button>
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import doctoresController from '../../../utils/http/Doctores';
import bootstrap from "bootstrap/dist/js/bootstrap";

export default {
  data() {
    return {
      doctor: {
        Nombre: "",
        Apellidos: "",
        Especialidad: "",
        TipoIdentificacion: 1,
        Identificacion: "",
        Exequartur: "",
        Telefono: "",
        Telefono2: "",
        Movil: "",
        Correo: "",
      },
    };
  },
  methods: {
    async createDoctor() {
      const response = await doctoresController.createDoctor(this.doctor);
      if (response.ok) {
        alert('Doctor creado con éxito');
        this.resetForm();
        this.closeModal(); 
      } else {
        alert('Error al crear doctor: ' + (response.error || 'Error desconocido'));
      }
    },
    resetForm() {
      this.doctor = {
        Nombre: "",
        Apellidos: "",
        Especialidad: "",
        TipoIdentificacion: 1,
        Identificacion: "",
        Exequartur: "",
        Telefono: "",
        Telefono2: "",
        Movil: "",
        Correo: "",
      };
    },
    closeModal() {
      const modalElement = document.getElementById('DoctoresModal');
      const modal = bootstrap.Modal.getInstance(modalElement);
      if (modal) {
        modal.hide(); 
      }
    }
  }
};
</script>

<style scoped>
.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  background-color: #218838; 
}
</style>
